<template lang="pug">
    v-app
        v-app-bar(ref='appBar' app='', color="#000000", v-if="displayTopandBotNav && !livecast", dark='' hide-on-scroll)
          .enforceMaxWidthRow
            v-col
              img.topLeftCorner(src="./assets/juke-logo-white-md.png")
            v-spacer
            //- FAQs section
            v-btn(icon title='FAQs' @click='openFAQs')
              v-icon(size='40') mdi-help-circle-outline
            //- Backdoor into accounts
            v-menu(v-if='isAdmin' :close-on-click='false' :close-on-content-click='false' offset-y ref='bandsMenuRef')
              template(v-slot:activator="{ on, attrs }")
                v-btn(color="primary", dark, @click='loadBands' v-bind="attrs", v-on="on", style='margin-left:18px !important;') {{ chooseBandText }}
              .bandNameHolder
                .bandNameSearchRow
                  v-text-field(v-model='searchText', label='Filter' outlined hide-details clearable)
                .bandNameDiv(v-for="(band, index) in allBandsFiltered" :key="index" @click='mockCurrentUser(band)')
                  b {{ band.name }}
                  p {{ band.ID }}
        v-main
          v-container.contentFill(fluid style='height:100%;width:100%;')
            router-view(v-slot="{ Component, route }" :key='routerKeyValue')
              transition(:name="route.meta.transition")
                component(:is="Component")
        v-snackbar(v-model='snackbar', timeout='2500', color="transparent" app)
            v-alert(type='success') {{ snackBarText }}
        v-bottom-navigation(app height='100px' v-if="displayTopandBotNav && !livecast")
          .bottomNavigation
            .enforceMaxWidthRow
              v-tooltip(top)
                template(v-slot:activator="{ on, attrs }")
                  .iconHolder(@click='handleRedirect("/p/home")' v-bind="attrs" v-on="on")
                    v-icon(:color='homeColor' size='30') mdi-home-outline
                    .iconText(:style='`color:${homeColor} !important;`') Home
                span Home - See an overview of your band and upcoming gigs.

              v-tooltip(top)
                template(v-slot:activator="{ on, attrs }")
                  .iconHolder(@click='handleRedirect("/p/schedule")' v-bind="attrs" v-on="on")
                    v-icon(:color='gigsColor' dark size='30') mdi-calendar-month-outline
                    .iconText(:style='`color:${gigsColor} !important;`') Schedule
                span Schedule - Create a new gig or edit existing ones.

              v-tooltip(top)
                template(v-slot:activator="{ on, attrs }")
                  .iconHolder( @click='handleRedirect("/p/requests")' v-bind="attrs" v-on="on")
                    img.pulsingIcon(v-if='pulsingIcon' src='./assets/Juke-Icon-Circle-Play-white.png' width='55px' height='55px')
                    img.notPulsingIcon(v-else-if='whiteCircle' src='./assets/Juke-Icon-Circle-Play-white.png' width='55px' height='55px')
                    img.notPulsingIcon(v-else src='./assets/Juke-Icon-Circle-Play.png' width='55px' height='55px')
                    .iconText(:style='`color:${middleColor} !important;`') Live Gig
                span Live Gig - Interact with your current gig.

              v-tooltip(top)
                template(v-slot:activator="{ on, attrs }")
                  .iconHolder(@click='handleRedirect("/p/songlist-menu")' v-bind="attrs" v-on="on")
                    v-icon(:color='songsColor' dark size='30') {{ mdiMusicBoxMultipleOutline }} 
                    .iconText(:style='`color:${songsColor} !important;`') Songlists
                span Songlists - Create a new songlist or edit existing ones.
                
              v-tooltip(top)
                template(v-slot:activator="{ on, attrs }")
                  .iconHolder(@click='handleRedirect("/p/directory")' v-bind="attrs" v-on="on")
                    v-icon(:color='settingsColor' size='30') {{ mdiCardAccountDetailsOutline  }}
                    .iconText(:style='`color:${settingsColor} !important;`') More
                span More - Edit information and explore additional pages.
        FAQDialog(ref='faqs' v-if='loggedIn')  
        UserNumberDialog(ref='userNumberDialog' v-if='loggedIn')
</template>

<script>
import {
  mdiCardAccountDetailsOutline,
  mdiMusicBoxMultipleOutline,
} from "@mdi/js";

import {
  getAllBands,
  getAllGigsForBand,
  getUser,
} from "./services/firebaseService";
const default_layout = "empty";
import FAQDialog from "./components/FAQDialog.vue";
import { nonPersistentStore } from "./store/store";
import UserNumberDialog from "./views/p/Popups/UserNumberDialog.vue";

export default {
  data: () => ({
    nonPersistentStore,
    intervalCall: null,
    menuVisible: false,
    allBands: [],
    chooseBandText: "Choose Band",
    routerKey: 0,
    mdiMusicBoxMultipleOutline,
    mdiCardAccountDetailsOutline,
    pulsingIcon: false,
    interval1: null,
    interval2: null,
    livecast: false,
    homePopUp: false,
    //survey: false, // to be updated under survey conditions
    bandInfo: {},
    gigs: [],
    venue: "",
    deferredPrompt: null,
    snackbar: false,
    snackBarText: "",
    searchText: "",
  }),
  components: {
    FAQDialog,
    UserNumberDialog,
  },
  computed: {
    allBandsFiltered() {
      if (!this.searchText || this.searchText === "") {
        return this.allBands;
      } else {
        return this.allBands.filter((band) => {
          return band.name
            .toLowerCase()
            .includes(this.searchText.toLowerCase());
        });
      }
    },
    isAdmin() {
      return nonPersistentStore?.getters?.isAdmin;
    },
    loggedIn() {
      const user = nonPersistentStore.getters.isLoggedIn;
      return user !== undefined && user !== null;
    },
    routerKeyValue() {
      return this.$route.path + this.routerKey;
    },
    whiteCircle() {
      const routeName = this.$route.name;
      return routeName === "Requests";
    },
    displayTopandBotNav() {
      const route = this.$route;
      const meta = route.meta;
      return (
        meta.requiresFirebaseUser === true && meta.hideTopAndBotNav !== true
      );
    },
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    },
    middleColor() {
      const routeName = this.$route.name;
      return routeName === "Requests" ? "white" : "#777777";
    },
    homeColor() {
      const routeName = this.$route.name;
      return routeName === "Home" ? "white" : "#777777";
    },
    songsColor() {
      const routeName = this.$route.name;
      return routeName === "Songlist" || routeName === "Songlist Menu"
        ? "white"
        : "#777777";
    },
    gigsColor() {
      const routeName = this.$route.name;
      return routeName === "Schedule" ? "white" : "#777777";
    },
    settingsColor() {
      const routeName = this.$route.name;
      return routeName === "Directory" ? "white" : "#777777";
    },
  },
  async mounted() {
    this.checkIfLive();
    this.interval1 = setInterval(this.checkIfLive, 60 * 1000);

    this.checkIfLivecast();
    this.interval2 = setInterval(this.checkIfLivecast, 500);

    if (nonPersistentStore.state.currentlyLoggedInBand) {
      this.checkIfMissingNumber();
    }

    const localThis = this;
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      localThis.deferredPrompt = e;
      setTimeout(() => {
        localThis.homePopUp = true;
      }, 15000);
    });
    window.addEventListener("appinstalled", () => {
      this.homePopUp = false;
    });
  },
  beforeDestroy() {
    clearInterval(this.interval1);
    clearInterval(this.interval2);
  },
  watch: {
    "nonPersistentStore.state.currentlyLoggedInBand": function (
      newValue,
      oldValue
    ) {
      if (
        (newValue && !oldValue) ||
        (newValue &&
          newValue.ID &&
          oldValue &&
          oldValue.ID &&
          newValue.ID !== oldValue.ID)
      ) {
        this.checkIfMissingNumber();
      }
    },
  },
  methods: {
    async loadBands() {
      if (!this.allBands?.length) {
        const localThis = this;
        getAllBands().then((allBands) => {
          localThis.allBands = allBands
            .filter((elem) => elem.name)
            .sort((a, b) => {
              if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
              }
              if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
              }
              return 0;
            });
        });
      }
    },
    async checkIfMissingNumber() {
      const user = nonPersistentStore.state.currentFirebaseUser;
      if (user) {
        const { uid, completedSignup } = user;
        const userObject = await getUser({ uid });
        if (userObject) {
          const { phoneNumber } = userObject;
          if (!phoneNumber || phoneNumber.length === 0) {
            if (completedSignup) {
              this.$refs.userNumberDialog.openDialog();
            }
          }
        }
      }
    },
    openFAQs() {
      this.$refs.faqs.openDialog();
    },
    async checkIfLivecast() {
      if (
        this.$route.name == "Juke Livecast1" ||
        this.$route.name == "Juke Livecast2"
      ) {
        this.livecast = true;
      } else {
        this.livecast = false;
      }
    },
    async checkIfLive() {
      // this should run if there is a current user
      const band = nonPersistentStore.state.currentlyLoggedInBand;
      if (band && band.ID) {
        const bandId = band.ID;
        let gigs = await getAllGigsForBand({ authId: bandId });
        const now = new Date();
        gigs = gigs.filter(
          (elem) => elem.endDate > now && (!elem.hidden || elem.hidden !== true)
        );
        if (gigs.length > 0) {
          this.pulsingIcon = true;
          clearInterval(this.interval1);
        } else {
          this.pulsingIcon = false;
        }
      }
    },
    handleRedirect(p) {
      const path = this.$route.path;
      this.$refs.appBar.isActive = true;
      if (p !== path) {
        this.$router.push(p);
        scroll(0, 0);
      }
    },
    openSupportLink() {
      window.open("mailto:support@juke.band");
    },
    mockCurrentUser(band) {
      nonPersistentStore.commit("setOtherBand", band.ID);
      this.chooseBandText = band.name;
      this.routerKey++;
      this.$refs.bandsMenuRef.isActive = false;
    },
    closePopUp() {
      this.homePopUp = false;
    },
  },
};
</script>

<style lang="scss">
.padCard {
  padding: 100px !important;
}
.bandNameHolder {
  height: 300px !important;
  z-index: 500 !important;
  background: white;
  overflow-y: scroll;
}
.bandNameDiv {
  cursor: pointer;
  border: 1px solid black;
  text-align: center;
}
.bandNameDiv:hover {
  background-color: #c9ddff;
}
.bottomNavigation {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: black;
  width: 100%;
}
.iconHolder {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  height: 100%;
  justify-content: center;
  animation: 0.5s fadeIn;
}
.iconHolder:hover {
  background-color: #282727;
  cursor: pointer;
}
.iconText {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  color: #777777;
  margin-top: 9px;
}
.notPulsingIcon {
  margin-top: -20px;
  cursor: pointer;
  border-radius: 50%;
  background: black;
}
.pulsingIcon {
  margin-top: -20px;
  cursor: pointer;
  border-radius: 50%;
  animation: pulse 2s infinite, gradient 15s ease infinite;
  background: linear-gradient(
    -45deg,
    #ad509e,
    #e72754,
    #f15a22,
    #ef4e81,
    #f6862a
  );
  background-size: 300% 300%;
}
@keyframes pulse {
  0% {
    transform: scale(0.85);
  }
  70% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.85);
  }
}
.topLeftCorner {
  max-height: 40px;
  background-size: 300% 300%;
  background: black;
}
.home-page-popup {
  display: flex;
  position: absolute;
  top: 0px;
  font-weight: bold;
  left: 0px;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    -45deg,
    #ad509e,
    #e72754,
    #f15a22,
    #ef4e81,
    #f6862a
  );
  color: white;
  padding: 50px;
  text-align: center;
}
.popup-container {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.popup-gif {
  text-align: center;
  font-size: 20px;
  margin-bottom: 20px;
}
.popup-btns {
  text-align: center;
}
.popup-btns button {
  margin-bottom: 10px !important;
}
.bandNameSearchRow {
  width: 100%;
  padding: 5px;
}
.contentFill {
  margin: 0px !important;
  padding: 0px !important;
  min-height: 100%;
  max-height: 100%;
  min-width: 100%;
}
.enforceMaxWidthRow {
  display: flex;
  flex-direction: row;
  max-width: 1600px;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
}
.globalFullPageContainerRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.globalFullPageContainerColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  animation: fadeIn 1s;
}
.globalPopupOverlay {
  background: rgba(0, 0, 0, 1);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.globalTextfield {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px;
  background-color: #151d29;
  border-radius: 15px;
  color: white;
}
.globalTextfield label {
  color: #bababa;
  font-size: 12px;
  margin-bottom: 5px;
}
.globalTextfield input {
  color: white;
  font-size: 16px;
  font-weight: bold;
  transition: border-color 0.3s ease;
  outline: none;
}
.globalTextfield input:focus,
.globalTextfield textarea:focus {
  border-bottom: 2px solid #531eff;
}
.globalTextfield textarea {
  color: white;
  width: 100%;
  font-size: 16px;
  resize: none;
  outline: none;
}
.globalFinePrint {
  color: #6a6a6a;
  font-size: 12px;
  text-align: center;
}
.globalHeroImageContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  max-height: 300px;
}
.globalHeroImageFade {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top;
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 80%,
    rgba(0, 0, 0, 0) 100%
  );
  -webkit-mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 80%,
    rgba(0, 0, 0, 0) 100%
  );
}
</style>
